var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"1"}},[_c(_setup.BackButton,{on:{"click":function($event){return _vm.$emit('goBackClicked')}}},[_vm._v(" Back To Houses ")])],1),_c('v-col',{attrs:{"cols":"12","md":"10"}},[_c('div',{staticClass:"text-h6 text-center font-weight-bold primary--text"},[_vm._v(" House Details ")])]),_c('v-spacer')],1),_c('v-row',{attrs:{"justify-md":"center"}},[(_vm.$mobileBreakpoint)?_c('v-col',{attrs:{"cols":"12"}},[_c(_setup.Table,{attrs:{"headers":_setup.smallScreenHouseDetails.tableHeaders,"items":_setup.smallScreenHouseDetails.houseDetailsEnclosedInArray}})],1):_vm._l((_setup.houseDetails),function(houseDetailSection,sectionName){return _c('v-col',{key:sectionName,attrs:{"cols":"12","md":"6"}},[_c('v-simple-table',{staticClass:"house-details-table",attrs:{"dense":""}},[_c('tbody',_vm._l((houseDetailSection),function(houseAttribute,attributeTitle){return _c('tr',{key:attributeTitle},[_c('td',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(attributeTitle)+" ")]),_c('td',[(attributeTitle !== 'Occupied')?[_vm._v(" "+_vm._s(houseAttribute))]:[(houseAttribute === true)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-check-circle")]):_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-close")])]],2)])}),0)])],1)})],2),_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c(_setup.ViewItemSectionTitle,[_vm._v("Ownership")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c(_setup.Table,{attrs:{"headers":[
          { text: 'Name', value: 'customer_name' },
          { text: 'ID No.', value: 'id_number' },
          { text: 'Phone No.', value: 'phone_number' },
          { text: 'Email', value: 'email' },
          { text: 'Status', value: 'status' },
          { text: 'Last Payment Date', value: 'last_paid' },
          { text: 'Paid Amount', value: 'last_paid_amount' },
          { text: 'Receipt No.', value: 'receipt_number', action: true },
          { text: 'Bill No.', value: 'bill_number', action: true },
        ],"dense":"","items":[_setup.ownerData],"bodyClasses":"text-caption"},scopedSlots:_vm._u([{key:"item.receipt_number",fn:function({ item: ownerData }){return [(!!ownerData.receipt_number)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-caption pl-0 pl-md-4",attrs:{"text":""},on:{"click":function($event){return _setup.onDownloadReceiptClicked(ownerData.receipt_number)}}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(ownerData.receipt_number)+" "),_c('v-icon',{attrs:{"small":"","right":"","color":"primary"}},[_vm._v("mdi-download-circle")])],1)]}}],null,true)},[_c('span',[_vm._v("Download Receipt")])]):_c('span',[_vm._v(" N/A ")])]}},{key:"item.bill_number",fn:function({ item: ownerData }){return [(!!ownerData.bill)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-caption pl-0 pl-md-4",attrs:{"text":""},on:{"click":function($event){return _setup.onDownloadBillClicked(ownerData.bill)}}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(ownerData.bill.bill_number)+" "),_c('v-icon',{attrs:{"small":"","right":"","color":"secondary"}},[_vm._v("mdi-download-circle")])],1)]}}],null,true)},[_c('span',[_vm._v("Download Bill")])]):_c('span',[_vm._v("N/A")])]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }