import { watch, computed } from "vue";
import apiCall from "@/utils/api.js";
import { usePaginatedDataWithDebouncedSearch } from "@/utils/vue_helpers";
import { useMutation } from "@tanstack/vue-query";

export function useViewHouses() {
  const {
    paginationData: housesData,
    searchModel,
    debouncedSearchModel,
    debouncedSearchModelNotEmpty,
  } = usePaginatedDataWithDebouncedSearch();

  const url = computed(() => {
    let url = `/api/client/houses?page=${housesData.paginationData.currentPage}`;
    if (debouncedSearchModelNotEmpty.value) {
      url = url + `&type=search&search=${debouncedSearchModel.value}`;
    }
    return url;
  });

  watch(
    url,
    (newVal) => {
      housesData.fetchData(newVal);
    },
    {
      immediate: true,
    }
  );

  return {
    housesData,
    searchModel,
  };
}

export function useGenerateHouseBill() {
  async function generateHouseBill(houseData) {
    try {
      const response = await apiCall({
        // url: "/api/client/generatehousebill",
        url: "/api/client/house/generate-bill",
        method: "POST",
        data: houseData,
      });
      //const { bill } = response;
      const bill = response;
      return bill;
    } catch (error) {
      throw error;
    }
  }

  const generateHouseBillMutation = useMutation({
    mutationFn: generateHouseBill,
  });

  return generateHouseBillMutation;
}
