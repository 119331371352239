<script setup>
import { computed } from "vue";
import { useDownloadFile, useRouter } from "@/utils/vue_helpers";
import { resetSnackbarOnMount } from "@/components/utils/snackbar/logic";
import ViewItemSectionTitle from "../utils/ViewItemSectionTitle.vue";
import Table from "../utils/Table.vue";
import { getFormattedBillDate, getFormattedMoney } from "@/utils/functions";
import BackButton from "../utils/BackButton.vue";

resetSnackbarOnMount();

const props = defineProps({
  house: {
    type: Object,
    default: null,
  },
  showGoBack: {
    type: Boolean,
    default: false,
  },
});

const houseNotEmpty = computed(() => {
  return props.house !== null;
});
const { downloadFile } = useDownloadFile();

function onDownloadBillClicked(bill) {
  downloadFile({ billId: bill.id, billNumber: bill.bill_number });
}

function onDownloadReceiptClicked(receiptNumber) {
  downloadFile({ receiptNumber });
}

const houseDetails = computed(() => {
  let houseDetails = {};
  if (houseNotEmpty.value) {
    houseDetails = getHouseDetails(props.house);
  }
  return houseDetails;
});

function getHouseDetails(house) {
  const houseDetails = {
    section1: {},
    section2: {},
  };
  houseDetails.section1["House/Stall No"] = house.house_no;
  houseDetails.section1["House No"] = house.floor_no;
  houseDetails.section1["Subcounty"] = house.subcounty.sub_county_name;
  houseDetails.section1["Ward"] = house.ward.ward_name;
  houseDetails.section1["Occupied"] = house.is_occupied === 1;
  houseDetails.section1["Estate/Group"] = house.house_estates.estate_name;
  houseDetails.section2["Current Tenant"] =
    house.housetenants.client.customer_name;
  houseDetails.section2["Tenancy Date"] = getFormattedBillDate(
    house.housetenants.created_at,
    false,
    false
  );
  houseDetails.section2["House/Stall Type"] = house.house_stall_type.houseType;
  houseDetails.section2["Monthly Rent"] = getFormattedMoney(house.monthly_rent);
  houseDetails.section2["Arrears"] =
    !!house.memoArrears || house.memoArrears === 0
      ? getFormattedMoney(house.memoArrears)
      : "N/A";
  houseDetails.section2["Outstanding Balance"] =
    house?.memoCurrentBalance || house?.memoCurrentBalance === 0
      ? getFormattedMoney(house.memoCurrentBalance)
      : "N/A";

  return houseDetails;
}

const ownerData = computed(() => {
  const house = props.house;
  const houseTenant = house.housetenants;
  const client = houseTenant.client;
  const bill =
    house.house_stall_bill?.[house.house_stall_bill.length - 1]?.bill;

  return {
    customer_name: client.customer_name,
    id_number: client.id_number,
    phone_number: client.phone_number1,
    email: client.email,
    status: house.housestatus.status_name,
    last_paid: getFormattedBillDate(houseTenant.last_paid_date),
    receipt_number: houseTenant?.invoice?.receipts?.[0]?.receipt_number,
    last_paid_amount: getFormattedMoney(houseTenant.last_paid_amount),
    bill_number: bill?.bill_number ?? "N/A",
    bill,
    tenancy_date: getFormattedBillDate(houseTenant.created_at),
  };
});

const smallScreenHouseDetails = computed(() => {
  const combinedHouseDetails = [
    houseDetails.value.section1,
    houseDetails.value.section2,
  ].reduce((previousVal, currentVal) => {
    Object.entries(currentVal).forEach(
      ([key, value]) => (previousVal[key] = value)
    );
    return previousVal;
  }, {});
  const tableHeaders = Object.keys(combinedHouseDetails).map(
    (houseDetailKey) => ({
      text: houseDetailKey,
      value: houseDetailKey,
    })
  );

  return {
    houseDetailsEnclosedInArray: [combinedHouseDetails],
    tableHeaders,
  };
});
</script>

<template>
  <v-container fluid class="pa-0">
    <v-row>
      <v-col cols="12" md="1">
        <BackButton @click="$emit('goBackClicked')">
          Back To Houses
        </BackButton>
      </v-col>
      <v-col cols="12" md="10">
        <div class="text-h6 text-center font-weight-bold primary--text">
          House Details
        </div>
      </v-col>
      <v-spacer />
    </v-row>
    <v-row justify-md="center">
      <v-col v-if="$mobileBreakpoint" cols="12">
        <Table
          :headers="smallScreenHouseDetails.tableHeaders"
          :items="smallScreenHouseDetails.houseDetailsEnclosedInArray"
        >
        </Table>
      </v-col>

      <template v-else>
        <v-col
          v-for="(houseDetailSection, sectionName) in houseDetails"
          :key="sectionName"
          cols="12"
          md="6"
        >
          <v-simple-table dense class="house-details-table">
            <tbody>
              <tr
                v-for="(houseAttribute, attributeTitle) in houseDetailSection"
                :key="attributeTitle"
              >
                <td class="font-weight-bold">
                  {{ attributeTitle }}
                </td>
                <td>
                  <template v-if="attributeTitle !== 'Occupied'">
                    {{ houseAttribute }}</template
                  >
                  <template v-else>
                    <v-icon v-if="houseAttribute === true" color="success"
                      >mdi-check-circle</v-icon
                    >
                    <v-icon v-else color="error">mdi-close</v-icon>
                  </template>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </template>
    </v-row>
    <v-row>
      <v-col cols="auto">
        <ViewItemSectionTitle>Ownership</ViewItemSectionTitle>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <Table
          :headers="[
            { text: 'Name', value: 'customer_name' },
            { text: 'ID No.', value: 'id_number' },
            { text: 'Phone No.', value: 'phone_number' },
            { text: 'Email', value: 'email' },
            { text: 'Status', value: 'status' },
            { text: 'Last Payment Date', value: 'last_paid' },
            { text: 'Paid Amount', value: 'last_paid_amount' },
            { text: 'Receipt No.', value: 'receipt_number', action: true },
            { text: 'Bill No.', value: 'bill_number', action: true },
          ]"
          dense
          :items="[ownerData]"
          bodyClasses="text-caption"
        >
          <template #item.receipt_number="{ item: ownerData }">
            <v-tooltip v-if="!!ownerData.receipt_number" bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  text
                  class="text-caption pl-0 pl-md-4"
                  @click="onDownloadReceiptClicked(ownerData.receipt_number)"
                  v-on="on"
                  v-bind="attrs"
                >
                  {{ ownerData.receipt_number }}
                  <v-icon small right color="primary"
                    >mdi-download-circle</v-icon
                  >
                </v-btn>
              </template>
              <span>Download Receipt</span>
            </v-tooltip>
            <span v-else> N/A </span>
          </template>

          <template #item.bill_number="{ item: ownerData }">
            <v-tooltip v-if="!!ownerData.bill" bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  text
                  class="text-caption pl-0 pl-md-4"
                  @click="onDownloadBillClicked(ownerData.bill)"
                  v-on="on"
                  v-bind="attrs"
                >
                  {{ ownerData.bill.bill_number }}
                  <v-icon small right color="secondary"
                    >mdi-download-circle</v-icon
                  >
                </v-btn>
              </template>
              <span>Download Bill</span>
            </v-tooltip>
            <span v-else>N/A</span>
          </template>
        </Table>

        <!-- <v-simple-table dense>
          <template #default>
            <thead>
              <tr>
                <th>Name</th>
                <th>ID No.</th>
                <th>Phone No.</th>
                <th>Email</th>
                <th>Status</th>
                <th>Last Payment Date</th>
                <th>Paid Amount</th>
                <th>Bill No.</th>
                <th>Tenancy Date</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td
                  v-for="(ownerItem, index) in ownerData"
                  :key="index"
                  class="text-caption"
                >
                  {{ ownerItem }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table> -->
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped lang="scss"></style>
