<script setup>
import { ref } from "vue";
import LabelledField from "../utils/LabelledField.vue";
import { fieldRequired, minValue, isNumber } from "@/utils/input_validation.js";
import { getFormattedMoney } from "@/utils/functions";
import BackButton from "../utils/BackButton.vue";

const props = defineProps({
  house: {
    type: Object,
    default: null,
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["payHouseClicked"]);

const amountToPay = ref("");

const form = ref(null);

const formValid = ref(false);

function onFormSubmitted() {
  const formValid = form.value.validate();
  if (formValid) {
    emit("payHouseClicked", {
      house: props.house,
      amountToPay: amountToPay.value,
    });
  }
}
</script>

<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="1">
        <BackButton @click="$emit('goBackClicked')">
          Back To Houses
        </BackButton>
      </v-col>
      <v-col
        cols="12"
        md="10"
        class="text-h6 text-center font-weight-bold primary--text"
      >
        Payment Request
      </v-col>
      <v-spacer />
    </v-row>

    <v-row justify="center">
      <v-col cols="auto" class="text-center font-weight-bold grey--text pb-8">
        House No {{ house.house_no }}</v-col
      >
    </v-row>
    <v-form ref="form" v-model="formValid" @submit.prevent="onFormSubmitted">
      <v-row>
        <v-col cols="12" md="6">
          <LabelledField label="Rent Arrears">
            <v-text-field
              class="text-field-background"
              outlined
              dense
              disabled
              :value="getFormattedMoney(house.housetenants.rent_arrears)"
              hide-details="auto"
            />
          </LabelledField>
        </v-col>
        <v-col cols="12" md="6">
          <LabelledField label="Monthly Rent">
            <v-text-field
              class="text-field-background"
              outlined
              dense
              disabled
              :value="getFormattedMoney(house.housetenants.monthly_rent)"
              hide-details="auto"
            />
          </LabelledField>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <LabelledField label="Penalties">
            <v-text-field
              class="text-field-background"
              outlined
              dense
              disabled
              :value="getFormattedMoney(house.housetenants.rent_penalties)"
              hide-details="auto"
            />
          </LabelledField>
        </v-col>
        <v-col cols="12" md="6">
          <LabelledField label="Total Outstanding">
            <v-text-field
              class="text-field-background"
              outlined
              dense
              disabled
              :value="getFormattedMoney(house.housetenants.current_balance)"
              hide-details="auto"
            />
          </LabelledField>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <LabelledField label="Amount To Pay" required>
            <v-text-field
              class="text-field-background"
              outlined
              dense
              v-model="amountToPay"
              hide-details="auto"
              :rules="[fieldRequired, isNumber, minValue(1)]"
            />
          </LabelledField>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-divider />
        </v-col>
      </v-row>
      <v-row justify="end">
        <v-col cols="auto">
          <v-btn class="text-none" color="error" @click="$emit('goBackClicked')"
            >Cancel<v-icon right>mdi-close</v-icon></v-btn
          >
        </v-col>
        <v-col cols="auto">
          <v-btn
            class="text-none"
            color="success"
            type="submit"
            :disabled="!formValid"
            :loading="loading"
            >Pay<v-icon right>mdi-cash</v-icon></v-btn
          >
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>
