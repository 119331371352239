var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{style:({ position: 'relative' })},[_c(_setup.Table,{attrs:{"headers":[
            { text: 'UHN/USN', value: 'house_no' },
            { text: 'House No', value: 'floor_no' },
            {
              text: 'SubCounty',
              value: 'subcounty',
              fn: (house) => house.subcounty.sub_county_name,
            },
            {
              text: 'Last Pay Date',
              value: 'lastPayDate',
              fn: (house) => _setup.getFormattedBillDate(house.created_at),
            },
            {
              text: 'Monthly Rent',
              value: 'monthlyRent',
              fn: (house) => _setup.getFormattedMoney(house.monthly_rent),
            },
            {
              text: 'Current Balance',
              value: 'currentBalance',
              fn: (house) =>
                house?.memoCurrentBalance || house?.memoCurrentBalance === 0
                  ? _setup.getFormattedMoney(house.memoCurrentBalance)
                  : 'N/A',
            },
            { text: 'View', value: 'view', action: true },
            { text: 'Pay', value: 'pay', action: true },
          ],"items":_vm.houses,"loading":_vm.loading,"emptyText":"No Houses Found","headClasses":"primary white--text"},scopedSlots:_vm._u([{key:"item.view",fn:function({ item: house }){return [_c(_setup.ActionButton,{attrs:{"action":_setup.actions.view,"inTable":""},on:{"click":function($event){return _vm.$emit('viewHouse', house)}}})]}},{key:"item.pay",fn:function({ item: house }){return [_c(_setup.ActionButton,{attrs:{"action":_setup.actions.pay,"inTable":""},on:{"click":function($event){return _vm.$emit('payForHouse', house)}}})]}}])})],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }