<script setup>
import { computed } from 'vue';

const props = defineProps({
    totalNumberOfItems: {
        type: Number,
        default: null
    },
    itemsPerPage: {
        type: Number,
        default: null
    },
    currentPage: {
        type: Number,
        default: null
    }
})

const paginationComponentLength = computed(() => {
    const moreThanZeroItems = props.totalNumberOfItems > 0
    if (moreThanZeroItems) {
        return Math.ceil(props.totalNumberOfItems / props.itemsPerPage)
    }
    return null
})

const shouldShowPagination = computed(() => {
    return paginationComponentLength.value !== null
})
</script>

<template>
    <v-pagination v-if="shouldShowPagination" :length="paginationComponentLength" total-visible="10" :value="currentPage"
        @input="$emit('paginationClicked', $event)" circle />
</template>