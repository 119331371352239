<script setup>
import { useViewHouses, useGenerateHouseBill } from "./view_houses_logic";
import ViewHousesTable from "./ViewHousesTable.vue";
import ViewHouse from "./ViewHouse.vue";
import LabelledField from "@/components/utils/LabelledField.vue";
import { computed, ref } from "vue";
import { resetSnackbarOnMount, snackbarData } from "@/components/utils/snackbar/logic";
import { useRouter, useRouterFromInstance } from "@/utils/vue_helpers";
import Pagination from "../utils/Pagination.vue";
import LoadingArea from "../utils/LoadingArea.vue";
import { routes } from "@/router/routes";
import PayForHouse from "./PayForHouse.vue";

resetSnackbarOnMount();

const { router } = useRouterFromInstance();

const { housesData, searchModel } = useViewHouses();

const generateHouseBill = useGenerateHouseBill();

const pages = {
  VIEW_HOUSES_TABLE: 0,
  VIEW_HOUSE: 1,
  VIEW_PAY_HOUSE: 2,
};
const currentPage = ref(pages.VIEW_HOUSES_TABLE); //ref(pages.VIEW_HOUSES_TABLE)

const houseUnderView = ref(null);
const houseUnderViewNotEmpty = computed(() => {
  return houseUnderView.value !== null;
});

function moveToViewHousePage(house) {
  houseUnderView.value = house;
  currentPage.value = pages.VIEW_HOUSE;
}

function moveToViewHouseTable() {
  currentPage.value = pages.VIEW_HOUSES_TABLE;
}

function onViewPayClicked(house) {
  houseUnderView.value = house;
  currentPage.value = pages.VIEW_PAY_HOUSE;
}

async function onPayHouseClicked({ house, amountToPay }) {
  generateHouseBill.mutate(
    {
      // rent_arrears: house.housetenants.rent_arrears,
      // monthly_rent: house.housetenants.monthly_rent,
      // current_balance: house.housetenants.current_balance,
      // amount_to_pay: amountToPay,
      // house_no: house.house_no,
      // sub_county_id: house.sub_county_id,
      desired_amount : amountToPay,
      house_stall_id : house.id,
    },
    {
      onSuccess(bill) {
        snackbarData.text = "House Bill Generated";
        snackbarData.color = "success";
        snackbarData.open = true;

        const paymentRoute = routes.payment.withQuery({
          billNumbersToPayAsString: bill.bill_number,
          directPayment: true,
          billsToPay: [bill],
        });
        router.push(paymentRoute);
      },
    }
  );
}

async function onPaginationClicked(newPage) {
  housesData.paginationData.currentPage = newPage;
}
</script>

<template>
  <v-container fluid class="pa-0">
    <v-tabs-items v-model="currentPage" touchless continuous>
      <v-tab-item>
        <v-row>
          <v-col cols="12">
            <div class="text-h6 text-center font-weight-bold primary--text">
              Houses / Stalls
            </div>
          </v-col>
        </v-row>
        <v-row justify-md="center" dense>
          <v-col cols="12" md="6">
            <LabelledField label="Search">
              <v-text-field
                class="background text-field"
                outlined
                dense
                v-model="searchModel"
                clearable
              />
            </LabelledField>
          </v-col>
        </v-row>
        <LoadingArea :loading="housesData.fetchingData" class="mt-4">
          <v-row>
            <v-col cols="12">
              <ViewHousesTable
                :houses="housesData.fetchedData"
                :loading="housesData.fetchingData"
                @viewHouse="moveToViewHousePage"
                @payForHouse="onViewPayClicked"
              />
            </v-col>
          </v-row>
          <v-row justify="end">
            <v-col cols="auto">
              <Pagination
                :current-page="housesData.paginationData.currentPage"
                :total-number-of-items="housesData.paginationData.total"
                :items-per-page="housesData.paginationData.perPage"
                @paginationClicked="housesData.setNewPage($event)"
              >
              </Pagination>
            </v-col>
          </v-row>
        </LoadingArea>
      </v-tab-item>

      <v-tab-item>
        <v-row>
          <v-col cols="12">
            <ViewHouse
              :house="houseUnderView"
              showGoBack
              @goBackClicked="moveToViewHouseTable"
            />

            <!-- <ViewHouses :house="houses" showGoBack @goBackClicked="moveToViewHouseTable" />  -->
          </v-col>
        </v-row>
      </v-tab-item>

      <v-tab-item>
        <v-row>
          <v-col cols="12">
            <PayForHouse
              :house="houseUnderView"
              :loading="generateHouseBill.isLoading.value"
              @goBackClicked="moveToViewHouseTable"
              @payHouseClicked="onPayHouseClicked"
            ></PayForHouse>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>
